import HeaderBanner from "./components/HeaderBanner";
import ChatPlugin from "./components/ChatPlugin";

function App() {


    return (
        <>
            <HeaderBanner/>
            <ChatPlugin/>
        </>
    );
}

export default App;
