import React, {useEffect, useState} from 'react';
import InputMask from "react-input-mask";
import {useForm} from "react-hook-form";
import axios from "axios";

const ChatPlugin = () => {
    const [errorText, setErrorText] = useState(false)
    const [succes, setSucces] = useState(false)
    const [addClass, setAddClass] = useState('h-0')
    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm()

    const sendDataToBack = (data) => {
        const editData = {...data}
        const ACTION_POST = '/post.php'

        axios.post(ACTION_POST, JSON.parse(JSON.stringify(editData)), {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
        })
            .then(function (response) {
                setSucces(true)
                reset()
                setErrorText(false)
                console.log(editData)
            })
            .catch(function (error) {
                setErrorText(true)
                console.log(error)
            });
    }

    useEffect(() => {
        setTimeout(() => {
            setAddClass('min-h-auto')
        }, 5000)
    }, [])

    const Collapse = () => {
        addClass === 'min-h-auto' ? setAddClass('h-0') : setAddClass('min-h-auto')
    }

    const text = 'Приветствую Вас! Я дежурный юрист сайта. Оказываю юридические услуги. Какая юридическая услуга вам требуется?'

    return (
        <div id={'chat_banner'}
             className={`fixed bottom-4 right-4 w-[350px] min-h-[30px] bg-amber-400 shadow-2xl rounded-md z-[9]`}>
            <div onClick={() => Collapse()}
                 className={'flex flex-row gap-2 p-1 px-2 justify-between cursor-pointer relative'}>
                <div className={'relative'}>
                    <img src="/icona.webp"
                         className={'w-[50px] rounded-[50%] p-1'} alt="lawer"/>
                    <span
                        className={'animate-ping absolute top-0 left-0 bg-blue-600 w-full h-full rounded-[50%] -z-10'}></span>
                    <div className={'absolute top-0 right-1 h-3 w-3'}>
                        <span
                            className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
                    </div>
                </div>
                <div className={'flex flex-col justify-center'}>
                    <h4 className={'font-bold mb-1'}>Иван Тарасов</h4>
                    <span className={'text-xs'}>Главный юрист</span>
                </div>
                <div className="relative flex h-3 w-3">
                    <span className={'h-3 w-3'}>X</span>
                </div>
            </div>
            <div
                className={`${addClass} bg-amber-50 transition overflow-hidden flex flex-col justify-between rounded-b-lg`}>
                <div className={'bg-white h-[60%] mb-2 p-4'}>
                    <p className={'m-0 text-md'}>{text}</p>
                </div>
                {errorText ? <p className={'text-red-700 px-2'}>Что то пошло не так! Попробуйте позже</p> : ''}
                {!succes ? <form className={'flex flex-col gap-2 px-2 pb-4'}
                                 onSubmit={handleSubmit((data) => sendDataToBack(data))}>
                    <textarea
                        className={'border-[1px] px-2 p-1 rounded-md text-lg'}
                        placeholder={'Опишите вашу проблему...'}
                        {...register("imya", {required: true})}
                    />
                    {errors.imya && <span className={'text-red-700'}>Это обязательное поле!</span>}
                    <InputMask
                        className={'border-[1px] px-2 p-1 rounded-md text-lg'}
                        placeholder={'+7 (999) 999-99-99'}
                        mask="+7 (999) 999-99-99"
                        {...register("phone", {required: true})}/>
                    {errors.phone && <span className={'text-red-700'}>Это обязательное поле!</span>}
                    <input id={'submit_form'} className={'bg-blue-600 text-white py-2 rounded-md cursor-pointer'}
                           type="submit"
                           value={'Получить помощь'}/>
                </form> : <p className={'text-green-500'}>Спасибо! Юрист скоро свяжется с Вами!</p>}
                <p className={'px-4 text-xs pb-4'}>Нажимая кнопку "Отправить" вы соглашаетесь с нашей <a
                    href={'https://www.regurs24.ru/politika-konfidecialnosti'} target={'_blank'}
                    className={'text-blue-800'}>политикой конфиденциальности</a></p>
            </div>
        </div>
    );
};

export default ChatPlugin;