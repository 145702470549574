import React, {useState} from 'react';
import InputMask from "react-input-mask";
import axios from "axios";
import {useCookies} from "react-cookie";
import {useForm} from "react-hook-form";

const HeaderBanner = () => {
    const [errorText, setErrorText] = useState(false)
    const [succes, setSucces] = useState(false)
    const [close, setClose] = useState(true)
    const [cookies, setCookie] = useCookies(['phone']);
    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm()

    const Coock = () => cookies.phone ? 'hidden' : ''

    const sendPhone = (data) => {
        const editData = {...data}
        const ACTION_POST = '/post.php'

        axios.post(ACTION_POST, JSON.parse(JSON.stringify(editData)), {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
        })
            .then(function (response) {
                setSucces(true)
                setTimeout(() => setCookie('phone', 'true', {path: '/'}), 3000)
                reset()
                setErrorText(false)
                console.log(editData)
            })
            .catch(function (error) {
                setErrorText(true)
                setCookie('phone', 'false', {path: '/'});
                console.log(error)
            });
    }
    const closeBanner = () => setClose(true)
    const stateBanned = () => {
        if (close) {
            return '-top-[200px] absolute'
        }
        return 'top-0 sticky'
    }
    setTimeout(() => setClose(false), 5000)
    return (
        <div
            id={'header_banner'}
            className={`${Coock()} min-h-[50px] left-0 w-full bg-amber-400 flex flex-col items-center py-2 ${stateBanned()}`}>
            <p className={'py-1 m-0 font-bold'}>Напишите ваш номер телефона, и юрист Вам перезвонит!</p>
            {errorText ? <p className={'text-red-700'}>Что то пошло не так! Попробуйте позже</p> : ''}
            {!succes ? <form className={'flex flex-row items-center justify-center gap-2'}
                             onSubmit={handleSubmit((data) => sendPhone(data))}>
                <InputMask
                    className={'border-[1px] px-2 p-1 rounded-md text-lg'}
                    placeholder={'+7 (999) 999-99-99'}
                    mask="+7 (999) 999-99-99"
                    {...register("phone", {required: true})}/>
                {errors.phone && <span className={'text-red-700'}>Это обязательное поле!</span>}
                <input className={'bg-blue-600 text-white py-2 rounded-md cursor-pointer px-4'} type="submit"
                       value={'Получить помощь'}/>
            </form> : <p className={'text-green-500'}>Спасибо! Юрист скоро свяжется с Вами!</p>}
            <p className={'px-4 text-xs'}>Нажимая кнопку "Отправить" вы соглашаетесь с нашей <a href={'https://www.regurs24.ru/politika-konfidecialnosti'} target={'_blank'} className={'text-blue-800'}>политикой конфиденциальности</a></p>
            <button onClick={closeBanner} className={'absolute right_position'}>
                x
            </button>
        </div>
    );
};

export default HeaderBanner;